import {
  Attribute,
  BodyStyleAttribute,
  Brand,
  Months,
  AttributeFigureAmountDisplayType,
  SplitModelByBrandType,
  DisplayStartingFromByBrandType,
  Arrow,
} from 'shared/types';
import {
  Benefits,
  CleanAirZone,
  ElectricRange,
  Emissions,
  EmployersInsurance,
  FuelCosts,
  FuelEfficiency,
  KeySpecification,
  Maintenance,
  MotabilityAdvancedPayment,
  MotabilityBodyStyle,
  MotabilityCoupe,
  MotabilityElectricRange,
  MotabilityEstate,
  MotabilityFuelCosts,
  MotabilityFuelEfficiency,
  MotabilityHatchback,
  MotabilityMpv,
  MotabilitySaloon,
  MotabilitySuv,
  MotabilityConvertible,
  Performance,
  ResidualValue,
  RoadTax,
} from 'features/vehicle-attributes/components/icons';
import { formatToCurrencyByPeriod } from 'features/total-cost-of-ownership/utils/attributes';
import { formatToCurrency } from 'shared/utils/currency';
import { TFunction } from 'next-i18next';

const ArrowButtonByBrand = {
  [Arrow.COLLAPSE]: {
    [Brand.AUDI]: '/assets/audi/Icons/chevron_up.svg',
    [Brand.DRIVVN]: '/collapse.svg',
    [Brand.VOLVO]: '/assets/volvo/Icons/chevron_up.svg',
    [Brand.WAYLANDS]: '/assets/volvo/Icons/chevron_up.svg',
    [Brand.MOTABILITY]: '/assets/motability/common/collapse.svg',
    [Brand.VOLKSWAGEN]: '/assets/volkswagen/Icons/chevron_up.svg',
  },
  [Arrow.EXPAND]: {
    [Brand.AUDI]: '/assets/audi/Icons/chevron_down.svg',
    [Brand.DRIVVN]: '/expand.svg',
    [Brand.VOLVO]: '/assets/volvo/Icons/chevron_down.svg',
    [Brand.WAYLANDS]: '/assets/volvo/Icons/chevron_down.svg',
    [Brand.MOTABILITY]: '/assets/motability/common/expand.svg',
    [Brand.VOLKSWAGEN]: '/assets/volkswagen/Icons/chevron_down.svg',
  },
  [Arrow.RIGHT]: {
    [Brand.AUDI]: '',
    [Brand.DRIVVN]: '',
    [Brand.VOLVO]: '',
    [Brand.WAYLANDS]: '',
    [Brand.MOTABILITY]: '',
    [Brand.VOLKSWAGEN]: '/assets/volkswagen/Icons/chevron_right.svg',
  },
};

const ArrowIconForSelectByBrand = {
  [Arrow.COLLAPSE]: {
    [Brand.AUDI]: '/assets/audi/Icons/chevron_up.svg',
    [Brand.DRIVVN]: '/collapse.svg',
    [Brand.VOLVO]: '/assets/volvo/Icons/chevron_up.svg',
    [Brand.WAYLANDS]: '/assets/volvo/Icons/chevron_up.svg',
    [Brand.MOTABILITY]: '/assets/motability/common/collapse.svg',
    [Brand.VOLKSWAGEN]: '/assets/volkswagen/Icons/chevron_up_select.svg',
  },
  [Arrow.EXPAND]: {
    [Brand.AUDI]: '/assets/audi/Icons/chevron_down.svg',
    [Brand.DRIVVN]: '/expand.svg',
    [Brand.VOLVO]: '/assets/volvo/Icons/chevron_down.svg',
    [Brand.WAYLANDS]: '/assets/volvo/Icons/chevron_down.svg',
    [Brand.MOTABILITY]: '/assets/motability/common/expand.svg',
    [Brand.VOLKSWAGEN]: '/assets/volkswagen/Icons/chevron_down_select.svg',
  },
};

const DisplayStartingFromByBrand: DisplayStartingFromByBrandType = {
  [Brand.DRIVVN]: true,
  [Brand.DRIVVNQA]: true,
  [Brand.VOLVO]: true,
  [Brand.WAYLANDS]: true,
  [Brand.MOTABILITY]: false,
};

const BodyStyleAttributesIcon = {
  [BodyStyleAttribute.SUV]: {
    title: 'results.attribute.body-style.suv.title',
    Icon: MotabilitySuv,
  },
  [BodyStyleAttribute.COUPE]: {
    title: 'results.attribute.body-style.coupe.title',
    Icon: MotabilityCoupe,
  },
  [BodyStyleAttribute.ESTATE]: {
    title: 'results.attribute.body-style.estate.title',
    Icon: MotabilityEstate,
  },
  [BodyStyleAttribute.HATCHBACK]: {
    title: 'results.attribute.body-style.hatchback.title',
    Icon: MotabilityHatchback,
  },
  [BodyStyleAttribute.MPV]: {
    title: 'results.attribute.body-style.mpv.title',
    Icon: MotabilityMpv,
  },
  [BodyStyleAttribute.SALOON]: {
    title: 'results.attribute.body-style.saloon.title',
    Icon: MotabilitySaloon,
  },
  [BodyStyleAttribute.CONVERTIBLE]: {
    title: 'results.attribute.body-style.convertible.title',
    Icon: MotabilityConvertible,
  },
};

const AttributeFigureAmountDisplay: AttributeFigureAmountDisplayType = {
  [Attribute.ACCELERATION]: 'block',
  [Attribute.BENEFIT_IN_KIND]: 'block',
  [Attribute.BODY_STYLE]: 'none',
  [Attribute.CLEAN_AIR_ZONE_CHARGES]: 'block',
  [Attribute.ELECTRIC_DRIVING_RANGE]: 'block',
  [Attribute.EMISSIONS]: 'block',
  [Attribute.EMPLOYERS_NATIONAL_INSURANCE]: 'block',
  [Attribute.EURO_EMISSION_STANDARD]: 'block',
  [Attribute.FUEL_COST]: 'block',
  [Attribute.FUEL_EFFICIENCY]: 'block',
  [Attribute.MAINTENANCE]: 'block',
  [Attribute.MILES_PER_KWH]: 'block',
  [Attribute.PERFORMANCE]: 'block',
  [Attribute.RESIDUAL_VALUE]: 'block',
  [Attribute.ROAD_TAX]: 'block',
  [Attribute.TOP_SPEED]: 'block',
};

const SplitModelByBrand: SplitModelByBrandType = {
  [Brand.VOLVO]: true,
  [Brand.WAYLANDS]: true,
  [Brand.MOTABILITY]: false,
  [Brand.DRIVVN]: true,
  [Brand.DRIVVNQA]: true,
};

const ATTRIBUTES = {
  [Attribute.EMISSIONS]: {
    title: 'results.attribute-header.emissions.title',
    Icon: {
      [Brand.VOLVO]: Emissions,
      [Brand.WAYLANDS]: Emissions,
      [Brand.DRIVVN]: Emissions,
    },
    IconValue: 'emissions',
    formatValue: (translate: TFunction) => (value: string) =>
      value === '0' ? value : translate('results.attribute.emissions.prefix', { value }),
    popup: 'results.attribute.emissions.popup',
  },
  [Attribute.MODEL]: {
    title: 'results.attribute-header.model.title',
    Icon: {
      [Brand.VOLVO]: KeySpecification,
      [Brand.WAYLANDS]: KeySpecification,
      [Brand.DRIVVN]: KeySpecification,
    },
    IconValue: 'key-specification',
  },
  [Attribute.PRICE]: {
    title: 'results.attribute-header.price.title',
    Icon: {
      [Brand.VOLVO]: KeySpecification,
      [Brand.WAYLANDS]: KeySpecification,
      [Brand.DRIVVN]: KeySpecification,
    },
    IconValue: 'residual-value',
  },
  [Attribute.FUEL_COST]: {
    title: 'results.attribute-header.fuel-cost.title',
    formatValue: () => formatToCurrency,
    Icon: {
      [Brand.VOLVO]: FuelCosts,
      [Brand.WAYLANDS]: FuelCosts,
      [Brand.MOTABILITY]: MotabilityFuelCosts,
      [Brand.DRIVVN]: FuelCosts,
    },
    IconValue: 'fuel-costs',
    popup: 'results.attribute.fuel-cost.popup',
  },
  [Attribute.ROAD_TAX]: {
    title: 'results.attribute-header.road-tax.title',
    formatValue: () => formatToCurrency,
    Icon: {
      [Brand.VOLVO]: RoadTax,
      [Brand.WAYLANDS]: RoadTax,
      [Brand.DRIVVN]: RoadTax,
    },
    IconValue: 'road-tax',
    popup: 'results.attribute.road-tax.popup',
  },
  [Attribute.CLEAN_AIR_ZONE_CHARGES]: {
    title: 'results.attribute-header.clean-air-zone-charges.title',
    formatValue: (translate: TFunction) => (value: string) =>
      value === '0' ? translate('results.attribute.clean-air-zone-charges.free') : formatToCurrency(value),
    Icon: {
      [Brand.VOLVO]: CleanAirZone,
      [Brand.WAYLANDS]: CleanAirZone,
      [Brand.DRIVVN]: CleanAirZone,
    },
    IconValue: 'clean-air-zone-charges',
    popup: 'results.attribute.clean-air-zone-charges.popup',
  },
  [Attribute.MAINTENANCE]: {
    title: 'results.attribute-header.maintenance.title',
    formatValue: () => formatToCurrencyByPeriod(Months.ONE_YEAR),
    Icon: {
      [Brand.VOLVO]: Maintenance,
      [Brand.WAYLANDS]: Maintenance,
      [Brand.DRIVVN]: Maintenance,
    },
    IconValue: 'maintenance',
    popup: 'results.attribute.maintenance.popup',
  },
  [Attribute.ELECTRIC_DRIVING_RANGE]: {
    title: 'results.attribute-header.electric-driving-range.title',
    Icon: {
      [Brand.VOLVO]: ElectricRange,
      [Brand.WAYLANDS]: ElectricRange,
      [Brand.MOTABILITY]: MotabilityElectricRange,
      [Brand.DRIVVN]: ElectricRange,
    },
    IconValue: 'electric-range',
    popup: 'results.attribute.electric-driving-range.popup',
  },
  [Attribute.BENEFIT_IN_KIND]: {
    title: 'results.attribute-header.benefit-in-kind.title',
    formatValue: () => formatToCurrency,
    Icon: {
      [Brand.VOLVO]: Benefits,
      [Brand.WAYLANDS]: Benefits,
      [Brand.DRIVVN]: Benefits,
    },
    IconValue: 'benefits',
    popup: 'results.attribute.benefit-in-kind.popup',
  },
  [Attribute.PERFORMANCE]: {
    title: 'results.attribute-header.performance.title',
    Icon: {
      [Brand.VOLVO]: Performance,
      [Brand.WAYLANDS]: Performance,
      [Brand.DRIVVN]: Performance,
    },
    IconValue: 'performance',
    popup: 'results.attribute.performance.popup',
  },
  [Attribute.FUEL_EFFICIENCY]: {
    title: 'results.attribute-header.fuel-efficiency.title',
    Icon: {
      [Brand.VOLVO]: FuelEfficiency,
      [Brand.WAYLANDS]: FuelEfficiency,
      [Brand.MOTABILITY]: MotabilityFuelEfficiency,
      [Brand.DRIVVN]: FuelEfficiency,
    },
    IconValue: 'fuel-efficiency',
    popup: 'results.attribute.fuel-efficiency.popup',
  },
  [Attribute.RESIDUAL_VALUE]: {
    title: 'results.attribute-header.residual-value.title',
    formatValue: () => formatToCurrency,
    Icon: {
      [Brand.VOLVO]: ResidualValue,
      [Brand.WAYLANDS]: ResidualValue,
      [Brand.DRIVVN]: ResidualValue,
    },
    IconValue: 'residual-value',
    popup: 'results.attribute.residual-value.popup',
  },
  [Attribute.EMPLOYERS_NATIONAL_INSURANCE]: {
    title: 'results.attribute-header.employers-national-insurance.title',
    formatValue: () => formatToCurrency,
    Icon: {
      [Brand.VOLVO]: EmployersInsurance,
      [Brand.WAYLANDS]: EmployersInsurance,
      [Brand.DRIVVN]: EmployersInsurance,
    },
    IconValue: 'employers-insurance',
    popup: 'results.attribute.employers-national-insurance.popup',
  },
  [Attribute.BODY_STYLE]: {
    title: 'results.attribute-header.body-style.title',
    Icon: {
      [Brand.MOTABILITY]: MotabilityBodyStyle,
      [Brand.DRIVVN]: MotabilityBodyStyle,
    },
    popup: 'results.attribute.body-style.popup',
  },
  [Attribute.ADVANCED_PAYMENT]: {
    title: 'results.attribute-header.advanced-payment.title',
    Icon: {
      [Brand.MOTABILITY]: MotabilityAdvancedPayment,
      [Brand.DRIVVN]: MotabilityAdvancedPayment,
    },
    popup: 'results.attribute.advanced-payment.popup',
  },
};

const DecisionTreeAttributes = {
  [Brand.VOLVO]: [
    Attribute.ELECTRIC_DRIVING_RANGE,
    Attribute.FUEL_EFFICIENCY,
    Attribute.MAINTENANCE,
    Attribute.PERFORMANCE,
  ],
  [Brand.WAYLANDS]: [
    Attribute.ELECTRIC_DRIVING_RANGE,
    Attribute.FUEL_EFFICIENCY,
    Attribute.MAINTENANCE,
    Attribute.PERFORMANCE,
  ],
  [Brand.MOTABILITY]: [
    Attribute.ELECTRIC_DRIVING_RANGE,
    Attribute.FUEL_EFFICIENCY,
    Attribute.FUEL_COST,
    Attribute.BODY_STYLE,
    Attribute.ADVANCED_PAYMENT,
  ],
};

const HasResultsBanner = {
  [Brand.AUDI]: false,
  [Brand.VOLVO]: true,
  [Brand.WAYLANDS]: true,
  [Brand.MOTABILITY]: false,
};

const HasEnhancedResultsSection = {
  [Brand.AUDI]: false,
  [Brand.VOLVO]: false,
  [Brand.WAYLANDS]: false,
  [Brand.MOTABILITY]: true,
};

const HasMapCalculatorDistance = {
  [Brand.AUDI]: false,
  [Brand.VOLVO]: false,
  [Brand.WAYLANDS]: false,
  [Brand.MOTABILITY]: true,
};

const SurveyEnhancedResultsThresholds = {
  [Brand.MOTABILITY]: {
    weightingMaximumThreshold: 100,
    milesMinimumThreshold: 100,
    milesMaximumThreshold: 200,
  },
};

const SurveyWeightingForMiles = {
  [Brand.MOTABILITY]: {
    weekDayMilesFor100Point: 200,
    weekendDayMilesFor100Point: 200,
    longestTripMilesFor100Point: 200,
  },
};

const HasEnhancedResultsAdditionLink = {
  [Brand.AUDI]: false,
  [Brand.VOLVO]: false,
  [Brand.WAYLANDS]: false,
  [Brand.MOTABILITY]: true,
};

const IsSaveToDatabaseEnabled = {
  [Brand.AUDI]: false,
  [Brand.DRIVVN]: false,
  [Brand.DRIVVNQA]: false,
  [Brand.MOTABILITY]: true,
  [Brand.OTHER]: false,
  [Brand.WAYLANDS]: false,
  [Brand.VOLVO]: false,
};

export {
  ATTRIBUTES,
  DecisionTreeAttributes,
  BodyStyleAttributesIcon,
  AttributeFigureAmountDisplay,
  SplitModelByBrand,
  DisplayStartingFromByBrand,
  ArrowButtonByBrand,
  ArrowIconForSelectByBrand,
  HasResultsBanner,
  HasEnhancedResultsSection,
  HasMapCalculatorDistance,
  SurveyEnhancedResultsThresholds,
  SurveyWeightingForMiles,
  HasEnhancedResultsAdditionLink,
  IsSaveToDatabaseEnabled,
};
