import { useEffect } from 'react';

interface Props {
  event: string;
  handler: EventListenerOrEventListenerObject;
  options?: boolean | AddEventListenerOptions;
}

const useEventListener = ({ event, handler, options = {} }: Props): void => {
  useEffect(() => {
    window.addEventListener(event, handler, options);

    return () => window.removeEventListener(event, handler);
  }, [event, handler, options]);
};

export { useEventListener };
