import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  arrow,
  autoUpdate,
  flip,
  FloatingArrow,
  offset,
  shift,
  useFloating,
  useInteractions,
  useClick,
  useHover,
  useFocus,
  useRole,
  useDismiss,
} from '@floating-ui/react';
import styled from 'styled-components';
import { useBrand } from 'shared/hooks/useBrand';
import { useTheme } from 'shared/hooks/useTheme';

type Placement =
  | 'top'
  | 'top-start'
  | 'top-end'
  | 'right'
  | 'right-start'
  | 'right-end'
  | 'bottom'
  | 'bottom-start'
  | 'bottom-end'
  | 'left'
  | 'left-start'
  | 'left-end';

const Arrow = styled(FloatingArrow)`
  ${({ theme }) => `
        fill: ${theme.components.infoPopup.arrowBackground};
    `}
`;

const TooltipContent = styled.div<{ placement: Placement }>`
  ${({ placement, theme }) => `
    background-color: ${theme.components.infoPopup.background};
    box-shadow: ${theme.components.infoPopup.boxShadow};
    padding: 16px;
    padding: ${theme.components.infoPopup.padding};
    border-radius: ${theme.components.infoPopup.borderRadius};
    margin: ${placement.includes('bottom') || placement.includes('top') ? '0 16px' : ''};
    max-width: 400px;
    display: flex;
    font: ${theme.components.infoPopup.text.font};
  `}
`;

const TooltipContentAndArrow = styled.div`
  z-index: 2;
`;

const TooltipTrigger = styled.img`
  ${({ theme }) => `
    height: ${theme.components.infoPopup.image?.height || 'initial'};
    width: ${theme.components.infoPopup.image?.width || 'initial'};
    cursor: pointer;
  `}
`;

const CloseIcon = styled.div`
  ${({ theme }) => `
    min-width: ${theme.components.infoPopup.close.width};
    display: ${theme.components.infoPopup.close.display};
    margin-left: 10px;
  `}
`;

interface Props {
  dataTest: string;
  children: React.ReactNode;
  className?: string;
  placement?: Placement;
  isFocusDisabled?: boolean;
  onOpen?: () => void;
}

export const Tooltip: React.FC<Props> = ({
  className,
  placement = 'bottom',
  children,
  dataTest,
  isFocusDisabled,
  onOpen = () => {},
}) => {
  const brand = useBrand();
  const arrowRef = useRef(null);
  const [isTooltipShown, setIsTooltipShown] = useState(false);

  const { refs, floatingStyles, context } = useFloating({
    open: isTooltipShown,
    onOpenChange: setIsTooltipShown,
    placement,
    middleware: [
      offset(10),
      flip(),
      shift(),
      arrow({
        element: arrowRef,
      }),
    ],
    whileElementsMounted: autoUpdate,
  });
  const role = useRole(context);
  const focus = useFocus(context);
  const dismiss = useDismiss(context);
  const click = useClick(context);
  const hover = useHover(context, { move: false });
  // Sometimes we need need to disable the focus prop, for instance if the tooltip is used within a modal that has a focus trap
  const focusProp = isFocusDisabled ? undefined : focus;
  const { getReferenceProps, getFloatingProps } = useInteractions([click, hover, dismiss, role, focusProp]);
  const theme = useTheme();
  const closeIconSource: string | boolean = theme.components.infoPopup.icon || false;

  const cachedOpenHandler = useCallback(onOpen, [onOpen]);

  useEffect(() => {
    if (isTooltipShown) {
      cachedOpenHandler();
    }
  }, [cachedOpenHandler, isTooltipShown]);

  const parsedDataTest = dataTest.toLowerCase().replaceAll(' ', '-');

  return (
    <>
      <TooltipTrigger
        ref={refs.setReference}
        src={`/assets/${brand}/common/info.svg`}
        role="tooltip"
        {...getReferenceProps()}
        className={className}
        tabIndex={0}
        data-test={`${parsedDataTest}-tooltip-trigger`}
      />
      {isTooltipShown ? (
        <TooltipContentAndArrow
          ref={refs.setFloating}
          style={floatingStyles}
          {...getFloatingProps()}
          onClick={() => setIsTooltipShown(false)}
          data-test={`${parsedDataTest}-tooltip-message`}
        >
          <TooltipContent placement={placement}>
            {children}
            {closeIconSource !== false && (
              <CloseIcon onClick={() => setIsTooltipShown(false)}>
                <img alt="close" src={closeIconSource} />
              </CloseIcon>
            )}
          </TooltipContent>
          <Arrow ref={arrowRef} context={context} />
        </TooltipContentAndArrow>
      ) : null}
    </>
  );
};
