import snakeCase from 'lodash/snakeCase';
import { Brand, JourneyType, BusinessJourneyType } from 'shared/types';

const makeAnalyticsPathPrefix = (
  brand: Brand,
  journeyType: JourneyType,
  businessJourneyType: BusinessJourneyType
): string => {
  return journeyType === JourneyType.PRIVATE
    ? `/${brand}/${journeyType}`
    : `/${brand}/${JourneyType.BUSINESS}/${snakeCase(businessJourneyType)}`;
};

export { makeAnalyticsPathPrefix };
