import React from 'react';
import { useTheme } from 'shared/hooks/useTheme';

interface Props extends React.HTMLProps<HTMLAnchorElement> {
  className?: string;
  text: string;
  icon?: string;
}

const Link: React.FC<Props> = ({ className = '', text, icon, ...props }) => {
  const theme = useTheme();
  const IconComponent = icon;

  return (
    <a className={`link ${className}`} {...(props as Record<string, unknown>)}>
      {text}

      {IconComponent !== undefined && <IconComponent />}

      <style jsx>
        {`
          .link {
            padding: 0;
            cursor: pointer;
            border: none;
            font: ${theme.components.vehicleConfirm.findAnotherVehicle.font};
            color: ${theme.colors.primary};
            text-decoration: ${theme.components.vehicleConfirm.findAnotherVehicle.textDecoration};
          }

          .link:hover {
            cursor: pointer;
            text-decoration: underline;
          }

          .link:focus {
            outline: thin dotted ${theme.colors.quaternary};
          }

          .link svg {
            margin-left: 5px;
          }
        `}
      </style>
    </a>
  );
};

export default Link;
