import useSWR from 'swr';
import isUndefined from 'lodash/isUndefined';
import { Product } from 'shared/constants';

const fetcher =
  <T>(tool: Product, method: 'GET' | 'POST' = 'GET', bodyData?: any) =>
  async ([url]: [url: string]): Promise<T> => {
    const headers = {
      'Content-Type': 'application/json',
      'x-product': tool,
    };

    const res = await fetch(url, {
      method,
      body: bodyData != null ? JSON.stringify(bodyData) : null,
      headers,
    });

    const body = await res.json();

    if (!res.ok || (body?.errors ?? []).length > 0) {
      throw new Error(body?.errors?.[0]?.code !== '' ? body?.errors?.[0]?.code : 'Unknown error');
    }

    return body.data;
  };

export interface UseQuery<T> {
  isLoading: boolean;
  data: T | undefined;
  error: Error;
}

const useQuery = <T>(
  shouldFetch: boolean,
  url: string,
  tool: Product,
  method: 'GET' | 'POST' = 'GET',
  bodyData?: any
): UseQuery<T> => {
  const { data, error } = useSWR(shouldFetch ? [url, bodyData] : null, fetcher<T>(tool, method, bodyData), {
    revalidateOnFocus: false,
    shouldRetryOnError: false,
  });

  return {
    isLoading: shouldFetch ? isUndefined(error) && isUndefined(data) : false,
    data,
    error,
  };
};

export { useQuery, fetcher };
