import { Product } from 'shared/constants';
import { useBrand } from 'shared/hooks/useBrand';
import snakeCase from 'lodash/snakeCase';
import { makeAnalyticsPathPrefix } from 'shared/utils/analytics';
import { useAnalytics } from 'shared/hooks/useAnalytics';
import { BusinessJourneyType, JourneyType } from 'shared/types';

export const useTooltipAnalytics = ({
  analyticsPrefix,
  businessJourneyType = BusinessJourneyType.COMPANY_CAR_DRIVER,
  journeyType = JourneyType.PRIVATE,
}: {
  analyticsPrefix: string;
  businessJourneyType?: BusinessJourneyType;
  journeyType?: JourneyType;
}) => {
  const brand = useBrand();
  const parsedAnalyticsPrefix = analyticsPrefix.toLowerCase().replaceAll(' ', '-');
  let analyticsPathPrefix: string;

  switch (parsedAnalyticsPrefix) {
    case 'results':
      analyticsPathPrefix = `/${brand}`;
      break;
    case 'running-costs':
      analyticsPathPrefix = `/${brand}/${Product.DETAILED_COMPARATOR}`;
      break;
    default:
      analyticsPathPrefix = makeAnalyticsPathPrefix(brand, journeyType, businessJourneyType);
  }

  const { logEvent } = useAnalytics(analyticsPathPrefix);

  return (attribute: string) => () => {
    const parsedAttribute = snakeCase(attribute.toLowerCase());
    logEvent(`/tooltip/${parsedAnalyticsPrefix}/${parsedAttribute}`);
  };
};
