import compose from 'lodash/flowRight';
import { formatToCurrency } from 'shared/utils/currency';
import { Months } from 'shared/types';

const divideByMonths =
  (months: Months) =>
  (value: number | null): number | null => {
    if (value === null) {
      return value;
    }

    return Math.round(value / months);
  };

const toNumber = (value: any): number | null => {
  return isNaN(parseInt(value)) ? null : parseInt(value);
};

const formatToCurrencyByPeriod =
  (months: Months) =>
  (value: string): string => {
    return compose(formatToCurrency, divideByMonths(months), toNumber)(value);
  };

export { formatToCurrencyByPeriod };
