import { JourneyType, Attribute, Comparison, Position, BusinessJourneyType, FormTypes } from 'shared/types';
import { mergeComparisons } from 'features/vehicle-comparisons/utils';

export interface State {
  comparisons: Comparison[];
  shouldFetchComparison: boolean;
  vehicleId: string;
  isChangeFormVisible: boolean;
  changeFormPosition: Position;
  journeyType: JourneyType;
  businessJourneyType: BusinessJourneyType;
  selectedAttribute: Attribute.NONE;
  isTotalCostOfOwnershipVisible: boolean;
  activeFormType: FormTypes | '';
  isVrmComparison: boolean;
  isFleetManagerWarningVisible: boolean;
  vrm: string;
}

export enum ActionType {
  SET_JOURNEY_TYPE = 'SET_JOURNEY_TYPE',
  SET_BUSINESS_JOURNEY_TYPE = 'SET_BUSINESS_JOURNEY_TYPE',
  SET_SHOW_CHANGE_FORM = 'SET_SHOW_CHANGE_FORM',
  SET_SELECTED_VEHICLE = 'SET_SELECTED_VEHICLE',
  SET_VEHICLE_COMPARISON = 'SET_VEHICLE_COMPARISON',
  SET_SHOW_TOTAL_COST_OF_OWNERSHIP = 'SET_SHOW_TOTAL_COST_OF_OWNERSHIP',
  SET_ACTIVE_FORM_TYPE = 'SET_ACTIVE_FORM_TYPE',
  SET_VRM_COMPARISON = 'SET_VRM_COMPARISON',
  SET_SHOW_FLEET_MANAGER_WARNING = 'SET_SHOW_FLEET_MANAGER_WARNING',
  SET_FLEET_MANAGER_WARNING_CONTINUE = 'SET_CONTINUE_FLEET_MANAGER_WARNING',
  SET_REMOVE_VRM_COMPARISON = 'SET_REMOVE_VRM_COMPARISON',
}

export const initialState: State = {
  comparisons: [],
  shouldFetchComparison: false,
  vehicleId: '',
  isChangeFormVisible: false,
  changeFormPosition: Position.LEFT,
  journeyType: JourneyType.PRIVATE,
  businessJourneyType: BusinessJourneyType.COMPANY_CAR_DRIVER,
  selectedAttribute: Attribute.NONE,
  isTotalCostOfOwnershipVisible: false,
  activeFormType: '',
  isVrmComparison: false,
  isFleetManagerWarningVisible: false,
  vrm: '',
};

export const reducer = (state: State, action): State => {
  switch (action.type) {
    case ActionType.SET_JOURNEY_TYPE:
      return {
        ...state,
        journeyType: action.journeyType,
        selectedAttribute: Attribute.NONE,
      };
    case ActionType.SET_BUSINESS_JOURNEY_TYPE:
      return {
        ...state,
        businessJourneyType: action.businessJourneyType,
        selectedAttribute: Attribute.NONE,
      };
    case ActionType.SET_SHOW_CHANGE_FORM:
      return {
        ...state,
        isChangeFormVisible: action.isChangeFormVisible,
        changeFormPosition: action.changeFormPosition,
      };
    case ActionType.SET_SELECTED_VEHICLE:
      return {
        ...state,
        isChangeFormVisible: false,
        shouldFetchComparison: true,
        vehicleId: action.vehicleId,
      };
    case ActionType.SET_VEHICLE_COMPARISON:
      return {
        ...state,
        shouldFetchComparison: false,
        comparisons: mergeComparisons(state, action),
      };
    case ActionType.SET_SHOW_TOTAL_COST_OF_OWNERSHIP:
      return {
        ...state,
        isTotalCostOfOwnershipVisible: action.isTotalCostOfOwnershipVisible,
      };
    case ActionType.SET_ACTIVE_FORM_TYPE:
      return {
        ...state,
        activeFormType: action.activeFormType,
      };
    case ActionType.SET_VRM_COMPARISON:
      return {
        ...state,
        isChangeFormVisible: false,
        isVrmComparison: true,
        vrm: action.vrm,
        vehicleId: '',
      };
    case ActionType.SET_SHOW_FLEET_MANAGER_WARNING:
      return {
        ...state,
        isFleetManagerWarningVisible: action.isVisible,
        changeFormPosition: Position.RIGHT,
      };
    case ActionType.SET_FLEET_MANAGER_WARNING_CONTINUE: {
      const { vehicleId } = action;
      return {
        ...state,
        shouldFetchComparison: true,
        vehicleId,
        isFleetManagerWarningVisible: false,
        journeyType: JourneyType.BUSINESS,
        businessJourneyType: BusinessJourneyType.FLEET_MANAGER,
        isVrmComparison: false,
      };
    }
    case ActionType.SET_REMOVE_VRM_COMPARISON:
      return {
        ...state,
        isVrmComparison: false,
      };
    default:
      throw new Error('Invalid action type');
  }
};
