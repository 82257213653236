const formatToCurrency = (
  amount: string | null,
  { locale = 'en-GB', currency = 'GBP', fallback = 'Unknown', minimumFractionDigits = 0, removeDecimals = true } = {}
): string => {
  if (amount === null || isNaN(+amount)) {
    return fallback;
  }

  let value = +amount;

  if (removeDecimals) {
    value = Math.round(value);
  }

  try {
    return new Intl.NumberFormat(locale, {
      style: 'currency',
      currency,
      minimumFractionDigits,
    }).format(value);
  } catch (err) {
    return fallback;
  }
};

export { formatToCurrency };
