import { Comparison, Position } from 'shared/types';
import { State } from './reducers';

const mergeComparisons = (state: State, action): Comparison[] => {
  return state.changeFormPosition === Position.LEFT
    ? [action.comparison, state.comparisons[Position.RIGHT]]
    : [state.comparisons[Position.LEFT], action.comparison];
};

export { mergeComparisons };
